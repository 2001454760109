const isLocalhost =
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1"
    ? true
    : false;

const config = {
  defaults: {
    namespace: "Application",
    api_url: isLocalhost
      ? "http://localhost:4000"
      : window.location.hostname === "mobile-staging.absavage.com"
      ? "https://staging-api.absavage.com"
      : "https://api.absavage.com",
  },
};

export default config;
