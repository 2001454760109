export const ActionType: any = {
  // User Actions

  CURRENT_USER: "CURRENT_USER",
  CURRENT_USER_FAILED: "CURRENT_USER_FAILED",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAIL:    "USER_LOGIN_FAIL",
  USER_PAYMENT_INFO:  "USER_PAYMENT_INFO",
  USER_TRANSACTIONS:  "USER_TRANSACTIONS",


};

export default ActionType;
