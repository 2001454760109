import SignupPage from 'pages/index';
import IRoute from '../interfaces/route';
const routes: IRoute[] = [
    {
        path: '/',
        name: 'Signup',
        component: SignupPage,
    },
]

export default routes;


